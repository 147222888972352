/* eslint-disable @typescript-eslint/lines-between-class-members */
import { captureException } from '@sentry/nextjs';
import { makeAutoObservable, runInAction } from 'mobx';
import orgService from '@/services/orgService';
import {
  FinishUploadResponse,
  InviteData,
  OrgInvoicesData,
  OrgMainFiles,
  OrgOtherFilesData,
  OrgStatsInfo,
  OrgStatsPeriod,
  OrgUsersData,
  Organization,
  PromoteToTypes,
  SessionsData,
  OrgFileType,
  StartUploadData,
  StartUploadResponse,
  OrgFileUrl,
  AccManager,
  OrgFeedbackData,
  GroupSessionsData,
  OrgUser,
  MarketingMaterialCategories,
  MarketingMaterial,
} from '@/types/Content/Org';
import toast from 'react-hot-toast';
import xw from 'xwind';
import differenceInDays from 'date-fns/differenceInDays';

export const coachesFirstPage = 0;

export const showErrorToast = (error: any) => {
  const message = error.response?.data?.detail || error.message;
  toast.error(typeof message === 'string' ? message : JSON.stringify(message), {
    duration: 3000,
    style: xw`px-4 text-active`,
  });
};
export const fileTypeToUrl = (fileKey: OrgFileType) =>
  fileKey.replaceAll('_', '-') as OrgFileUrl;

class OrgStore {
  orgLoading = false;
  orgLoaded = false;
  orgError: any;
  org: Organization | null = null;
  get onboardingFinished() {
    return this.org ? this.org?.onboarding_finished ?? false : true;
  }

  accManagerLoading = false;
  accManagerError = null;
  _accManager: AccManager = null;

  defaultAccManager: AccManager = {
    slug: 'linda-neff',
    first_name: 'Linda',
    last_name: 'Neff',
    image_url: '/images/ov-avatar/LindaNeffAvatar.png',
    calendly_link: 'https://calendly.com/onevillagescheduler/linda-neff',
    intercom_chat_init_message: '',
  };

  get accManager(): AccManager {
    return this._accManager || this.defaultAccManager;
  }

  get copayInfo(): Organization['copay_info'] {
    return this.org?.copay_info || null;
  }

  sessionsLoading = false;
  sessionsLoaded = false;
  sessionsError: any;
  sessions: SessionsData | null = null;

  groupSessionsLoading = false;
  groupSessionsLoaded = false;
  groupSessionsError: any;
  groupSessions: GroupSessionsData | null = null;

  usersLoading = false;
  usersLoaded = false;
  usersError: any;
  users: OrgUsersData | null = null;
  get showUsersSkeleon() {
    return this.usersLoading || !this.usersLoaded;
  }

  statsLoading = false;
  statsError: any;
  statsInfo: OrgStatsInfo = {};

  feedbackLoading = false;
  feedbackError: any;
  feedbackInfo: OrgFeedbackData = null;

  sendingInvite = false;

  invoicesLoading = false;
  invoicesLoaded = false;
  invoicesError: any;
  invoices: OrgInvoicesData | null = null;
  get showInvoisesSkeleon() {
    return this.invoicesLoading || !this.invoicesLoaded;
  }

  nextInvoiceDateLoading = false;
  nextInvoiceDateLoaded = false;
  nextInvoiceDate: string;

  mainFilesLoading = false;
  mainFilesLoaded = false;
  mainFilesError: any;
  mainFiles: OrgMainFiles = null;

  otherFilesLoading = false;
  otherFilesLoaded = false;
  otherFilesError: any;
  otherFiles: OrgOtherFilesData = null;

  selectedUsersMap = new Map<string, OrgUser>();

  marketingMaterialsLoading = false;
  marketingMaterialsError: any;
  marketingMaterials: Array<MarketingMaterial> | null = null;
  recentlyUploadedMarketingMaterials: Array<MarketingMaterial> | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  get isAdminUser(): boolean {
    return this.org?.role && this.org?.role === 'Admin';
  }
  get isOwnerUser(): boolean {
    return this.org?.role && this.org?.role === 'Owner';
  }
  get isAdminOrOwnerUser(): boolean {
    return this.isAdminUser || this.isOwnerUser;
  }

  get usersWithoutOwner() {
    return this.users?.items?.filter(user => user.role !== 'Owner') || [];
  }
  get isAllUsersSelected() {
    return this.usersWithoutOwner.length > 0 && this.selectedUsersMap.size === this.usersWithoutOwner.length;
  }

  resetData() {
    this.org = null;
    this.statsInfo = {};
    this.users = null;
    this.clearSelectedUsers()
  }

  clearSelectedUsers() {
    this.selectedUsersMap = new Map();
  }

  clearSelectedUser(userId: string) {
    this.selectedUsersMap.delete(userId);
  }

  toggleUser(user: OrgUser) {
    const userId = user?.id;

    if (this.selectedUsersMap.has(userId)) {
      this.selectedUsersMap.delete(userId);
    } else {
      this.selectedUsersMap.set(userId, user);
    }
  }

  selectAllUsers() {
    if (this.selectedUsersMap.size && this.selectedUsersMap.size === this.usersWithoutOwner.length) {
      this.clearSelectedUsers();
    } else {
      this.selectedUsersMap = new Map(this.usersWithoutOwner.map(user => [user.id, user]));
    }
  }

  async fetchOrg() {
    try {
      // if (this.orgLoaded && this.org?.id) return;

      runInAction(() => {
        this.orgLoading = true;
      });
      const orgRes = await orgService.getOrg();
      this.org = orgRes || null;
    } catch (error) {
      captureException(error);
      this.orgError = error.message;
    } finally {
      runInAction(() => {
        this.orgLoading = false;
        this.orgLoaded = true;
      });
    }
  }

  async fetchAccManager() {
    try {
      if (!this.org?.id || this._accManager) return;
      runInAction(() => {
        this.accManagerLoading = true;
      });
      const managerRes = await orgService.getAccManager(this.org?.id);
      this._accManager = managerRes;
    } catch (error) {
      captureException(error);
      this.accManagerError = error.message;
    } finally {
      runInAction(() => {
        this.accManagerLoading = false;
      });
    }
  }

  async getStats() {
    try {
      if (!this.org?.id) return;

      runInAction(() => {
        this.statsLoading = true;
      });

      const [
        { value: yearValue },
        { value: monthValue },
      ] = (await Promise.allSettled([
        orgService.getStats(this.org?.id, OrgStatsPeriod.year),
        orgService.getStats(this.org?.id, OrgStatsPeriod.month),
      ])) as any;

      this.statsInfo = {
        ...this.statsInfo,
        [OrgStatsPeriod.year]: yearValue || null,
        [OrgStatsPeriod.month]: monthValue || null,
      };
    } catch (error) {
      captureException(error);
      this.statsError = error.response?.data?.detail || error.message;
    } finally {
      runInAction(() => {
        this.statsLoading = false;
      });
    }
  }

  async getFeedback() {
    try {
      if (!this.org?.id) return;
      runInAction(() => {
        this.feedbackLoading = true;
      });
      const res = await orgService.getFeedback(this.org?.id);
      this.feedbackInfo = res;
    } catch (error) {
      captureException(error);
      this.feedbackError = error.response?.data?.detail || error.message;
    } finally {
      runInAction(() => {
        this.feedbackLoading = false;
      });
    }
  }

  async fetchUsers(params?: { search?: string; order_by?: string; order_dir?: string; }) {
    try {
      if (!this.org?.id) return;
      runInAction(() => {
        this.usersLoading = true;
      });
      const res = await orgService.getUsers(this.org?.id, params);
      if (res?.items) {
        this.users = res || null;
      }
    } catch (error) {
      captureException(error);
      this.usersError = error.response?.data?.detail || error.message;
    } finally {
      runInAction(() => {
        this.usersLoading = false;
        this.usersLoaded = true;
      });
    }
  }

  async promoteTo(employeeId: string, type: PromoteToTypes) {
    try {
      if (!this.org?.id || !employeeId || !type) return;

      const res = await orgService.promoteTo(this.org.id, employeeId, type);

      if (res?.id) {
        this.users = {
          ...this.users,
          items:
            this.users.items?.map((el) => (el.id === res.id ? res : el)) || [],
        };
      }
    } catch (error) {
      captureException(error);
      showErrorToast(error);
    }
  }

  async removeUser(employeeId: string) {
    try {
      if (!this.org?.id || !employeeId) return;
      await orgService.removeUser(this.org.id, employeeId);
      this.clearSelectedUser(employeeId);

      await this.fetchUsers();
    } catch (error) {
      captureException(error);
      showErrorToast(error);
    }
  }

  async removeUsers(employeesIds: string[]) {
    try {
      if (!this.org?.id || !employeesIds?.length) return;
      await orgService.removeUsers(this.org.id, employeesIds);
      this.clearSelectedUsers();
      await this.fetchUsers();
    } catch (error) {
      captureException(error);
      showErrorToast(error);
    }
  }

  async sendInvite(data: InviteData): Promise<{ err?: string | null }> {
    try {
      if (!this.org?.id) return {};

      runInAction(() => {
        this.sendingInvite = true;
      });

      await orgService.sendInvite(this.org.id, data);

      toast('Invited!', {
        duration: 3000,
        style: xw`px-4 text-active`,
      });
      return {};
    } catch (error) {
      captureException(error);
      return { err: error.response?.data?.detail?.[0]?.msg || error.message };
    } finally {
      runInAction(() => {
        this.sendingInvite = false;
      });
    }
  }

  async fetchSessions() {
    try {
      if (this.sessionsLoaded && this.sessions?.total) return;

      runInAction(() => {
        this.sessionsLoading = true;
      });
      const res = await orgService.getOrgSessions();
      this.sessions = res || null;
    } catch (error) {
      captureException(error);
      this.sessionsError = error.message;
    } finally {
      runInAction(() => {
        this.sessionsLoading = false;
        this.sessionsLoaded = true;
      });
    }
  }

  async fetchGroupSessions() {
    try {
      if (this.groupSessionsLoaded && this.groupSessions?.total) return;

      runInAction(() => {
        this.groupSessionsLoading = true;
      });
      const res = await orgService.getGroupSessions();
      this.groupSessions = res || null;
    } catch (error) {
      captureException(error);
      this.groupSessionsError = error.message;
    } finally {
      runInAction(() => {
        this.groupSessionsLoading = false;
        this.groupSessionsLoaded = true;
      });
    }
  }

  async fetchInvoices() {
    try {
      if (!this.org?.id) return;
      runInAction(() => {
        this.invoicesLoading = true;
      });
      const res = await orgService.getInvoices(this.org?.id);
      if (res?.items) {
        this.invoices = res || null;
      }
    } catch (error) {
      captureException(error);
      this.invoicesError = error.response?.data?.detail || error.message;
    } finally {
      runInAction(() => {
        this.invoicesLoading = false;
        this.invoicesLoaded = true;
      });
    }
  }
  async fetchNextInvoiceDate() {
    try {
      if (!this.org?.id) return;
      runInAction(() => {
        this.nextInvoiceDateLoading = true;
      });
      const res = await orgService.getNextInvoiceDate(this.org?.id);
      this.nextInvoiceDate = res?.date;
    } catch (error) {
      captureException(error);
      showErrorToast(error);
    } finally {
      runInAction(() => {
        this.nextInvoiceDateLoading = false;
        this.nextInvoiceDateLoaded = true;
      });
    }
  }

  /**
   * files
   */
  async fetchMainFiles(refresh = false) {
    try {
      if (!this.org?.id || (!refresh && this.mainFiles)) return;
      runInAction(() => {
        this.mainFilesLoading = true;
      });
      const res = await orgService.getMainFiles(this.org?.id);
      this.mainFiles = res || null;
    } catch (error) {
      captureException(error);
      showErrorToast(error);
      this.mainFilesError = error.response?.data?.detail || error.message;
    } finally {
      runInAction(() => {
        this.mainFilesLoading = false;
        this.mainFilesLoaded = true;
      });
    }
  }
  async fetchOtherFiles(refresh = false) {
    try {
      if (!this.org?.id || (!refresh && this.otherFiles)) return;
      runInAction(() => {
        this.otherFilesLoading = true;
      });
      const res = await orgService.getOtherFiles(this.org?.id);
      this.otherFiles = res || null;
    } catch (error) {
      captureException(error);
      showErrorToast(error);
      this.otherFilesError = error.response?.data?.detail || error.message;
    } finally {
      runInAction(() => {
        this.otherFilesLoading = false;
        this.otherFilesLoaded = true;
      });
    }
  }

  /**
   * file upload
   */
  async startUpload(
    data: StartUploadData,
  ): Promise<StartUploadResponse | null> {
    try {
      if (!this.org?.id) return null;
      const res = await orgService.startUpload(data);
      return res;
    } catch (error) {
      captureException(error);
      showErrorToast(error);
      return null;
    }
  }

  async finishUpload(
    fileId: string,
    fileKey: OrgFileType,
  ): Promise<FinishUploadResponse | null> {
    try {
      if (!fileId || !this.org?.id) return null;

      const res = await orgService.finishUpload(fileId);

      let fileInfo: any;
      if (['plan', 'team_mailing_list'].includes(fileKey)) {
        const filesInfo = await orgService.setMainOrgFile(
          this.org.id,
          res.id,
          fileTypeToUrl(fileKey),
        );
        this.mainFiles = filesInfo;
        fileInfo = filesInfo?.[`${fileKey}_file`];
        await this.fetchOrg();
      } else if (fileKey === 'other') {
        fileInfo = await orgService.setOtherOrgFile(this.org.id, res.id);
        this.fetchOtherFiles(true);
      } else if (fileKey === 'image') {
        fileInfo = await orgService.setOrgImage(this.org.id, res.id);
        await this.fetchOrg();
      }

      if (fileInfo?.file_id || fileInfo?.id || fileInfo?.file_url) {
        toast.success('Successfully added', {
          duration: 3000,
          style: xw`px-4 text-active`,
        });
      } else throw Error('Something went wrong, file was not uploaded');

      return res;
    } catch (error) {
      captureException(error);
      showErrorToast(error);
      if (['plan', 'team_mailing_list'].includes(fileKey))
        this.fetchMainFiles(true);
      else if (fileKey === 'other') this.fetchOtherFiles(true);
      else if (fileKey === 'image') this.fetchOrg();
      return null;
    }
  }

  async removeFile(fileKey: OrgFileType, fileId: string): Promise<void> {
    try {
      if (!this.org?.id) return;

      if (['plan', 'team_mailing_list'].includes(fileKey)) {
        const filesInfo = await orgService.removeMainOrgFile(
          this.org.id,
          fileTypeToUrl(fileKey),
        );
        this.mainFiles = filesInfo;
        await this.fetchOrg();
      } else if (fileKey === 'other' && fileId) {
        await orgService.removeOtherOrgFile(fileId, this.org.id);
        await this.fetchOtherFiles(true);
      }
    } catch (error) {
      captureException(error);
      showErrorToast(error);
      if (['plan', 'team_mailing_list'].includes(fileKey))
        this.fetchMainFiles(true);
      else if (fileKey === 'other') this.fetchOtherFiles(true);
    }
  }

  async fetchMarketingMaterials(
    params?: {
      type?: MarketingMaterialCategories;
      page?: number;
      limit?: number;
    },
  ) {
    try {
      if (!this.org?.id) return;
      runInAction(() => {
        this.marketingMaterialsLoading = true;
      });
      const res = await orgService.getMarketingMaterials({
        companyId: this.org?.id,
        ...params,
      });
      const items = res?.items || [];
      const normalizedItems = this.normalizeMarketingMaterials(items);
      this.marketingMaterials = normalizedItems;

      if (!params?.type) {
        this.recentlyUploadedMarketingMaterials = normalizedItems.slice(0, 3)
      }
    } catch (error) {
      captureException(error);
      showErrorToast(error);
      this.marketingMaterialsError = error.response?.data?.detail || error.message;
    } finally {
      runInAction(() => {
        this.marketingMaterialsLoading = false;
      });
    }
  }

  private normalizeMarketingMaterials(items: MarketingMaterial[]) {
    const now = new Date();

    return items
      // @ts-ignore
      .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
      .map((item) => {
        const isNew = differenceInDays(now, new Date(item.created_at)) <= 30;
        return {
          ...item,
          isNew,
        };
      });
  }
}

const store = new OrgStore();

export default store;
